import * as React from "react"
import PropTypes from "prop-types"
import logo from "../assets/logo_black.svg"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { navigate } from "gatsby"

const Header = () => {
  const { t, i18n } = useTranslation()
  const { languages, changeLanguage } = useI18next()
  const onLanguageChange = language => {
    if (window.location.pathname === "/en" && language === "en") {
      // do nothing
      return
    }
    changeLanguage(language)
    if (window.location.pathname === "/en" && language === "et") {
      navigate("/")
    }
  }

  return (
    <header>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link
            className="fs-5 py-2"
            style={{
              color: "black",
              textTransform: "uppercase",
              textDecoration: "none",
            }}
            to="/"
          >
            <img
              className="logo"
              src={logo}
              alt="Arkinet"
              width={120}
              height={22}
            />
          </Link>
          <div className="d-flex align-items-center">
            <Link className="mx-2 d-none d-sm-block" to="/">
              {t("home.title")}
            </Link>
            <Link className="mx-2" to="/images">
              {t("portfolio.title")}
            </Link>
            <Link className="mx-2" to="/contact">
              {t("contact.title")}
            </Link>
            <ul className="languages d-flex">
              {languages.map(language => (
                <li key={language}>
                  <button
                    style={{
                      fontWeight: language === i18n.language ? 600 : 300,
                    }}
                    className="remove_button_css"
                    onClick={() => onLanguageChange(language)}
                  >
                    {language}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
